const palette = {
  primary: '#101010',
  secondary: '#007A6C',
  white: '#FFF',
  mercury: '#DCE8E4',
  silver: '#F2F2F2',
  starDust: '#9E9E9E',
  ferrariRed: '#FA031F',
  nebula: '#C0DDD4',
  transparentBackground: 'rgba(255, 255, 255, 0.7)',
  buttonBackground: 'rgba(255, 255, 255, 0.32)',
  overlay: 'rgba(0, 0, 0, 0.6)',
};

export default palette;
