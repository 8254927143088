import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/system';
import * as React from 'react';
import theme from '../../src/theme';

const TopLayout = (properties) => {
  return (
    <React.Fragment>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {properties.children}
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  );
};

export default TopLayout;
