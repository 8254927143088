import { createTheme } from '@mui/material/styles';

import palette from './palette';

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 320,
      xs: 375,
      sm: 480,
      md: 680,
      lg: 900,
      xl: 1200,
      xxl: 1400,
      xxxl: 1670,
    },
  },

  typography: {
    color: palette.primary,
    fontFamily: ['Gilroy, sans-serif'].join(','),
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '26px',

    p: {
      margin: 0,
      padding: 0,
    },

    h1: {
      margin: 0,
      padding: 0,
      fontSize: '82px',
      fontWeight: 400,
      lineHeight: '86px',

      '& strong': {
        color: palette.secondary,
        fontSize: '82px',
        lineHeight: '86px',
      },

      '& i': {
        color: palette.secondary,
        fontSize: '82px',
        lineHeight: '86px',
      },

      '@media (max-width: 1399px)': {
        fontSize: '62px',
        lineHeight: '66px',

        '& strong, & i': {
          fontSize: '62px',
          lineHeight: '66px',
        },
      },

      '@media (max-width: 479px)': {
        fontSize: '36px',
        lineHeight: '42px',

        '& strong, & i': {
          fontSize: '36px',
          lineHeight: '42px',
        },
      },
    },

    h2: {
      margin: 0,
      padding: 0,
      fontSize: '64px',
      fontWeight: 500,
      lineHeight: '68px',

      '& strong': {
        fontSize: '64px',
        fontWeight: 700,
        lineHeight: '68px',
      },

      '& span': {
        color: palette.secondary,
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '48px',
      },

      '@media (max-width: 899px)': {
        fontSize: '32px',
        lineHeight: '38px',

        '& strong': {
          fontSize: '32px',
          lineHeight: '38px',
        },

        '& span': {
          fontSize: '32px',
          lineHeight: '38px',
        },
      },
    },

    h3: {
      margin: 0,
      padding: 0,
      fontSize: '48px',
      fontWeight: 500,
      lineHeight: '58px',

      '& strong': {
        fontSize: '48px',
        fontWeight: 700,
        lineHeight: '58px',
      },

      '@media (max-width: 1399px)': {
        fontSize: '36px',
        lineHeight: '38px',

        '& strong': {
          fontSize: '36px',
          lineHeight: '38px',
        },
      },

      '@media (max-width: 899px)': {
        fontSize: '32px',

        '& strong': {
          fontSize: '32px',
        },
      },
    },

    h4: {
      margin: 0,
      padding: 0,
      fontSize: '40px',
      fontWeight: 400,
      lineHeight: '48px',

      '& strong': {
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '48px',
      },

      '@media (max-width: 1399px)': {
        fontSize: '32px',
        lineHeight: '38px',

        '& strong': {
          fontSize: '32px',
          lineHeight: '38px',
        },
      },
    },

    caption: {
      margin: 0,
      padding: 0,
      fontSize: '32px',
      fontWeight: 500,
      lineHeight: '46px',

      '& strong': {
        fontSize: '32px',
        fontWeight: 700,
        lineHeight: '46px',
      },

      '& i': {
        color: palette.secondary,
        fontSize: '32px',
        fontWeight: 700,
        lineHeight: '46px',
      },

      '@media (max-width: 899px)': {
        fontSize: '24px',
        lineHeight: '36px',

        '& strong': {
          fontSize: '24px',
          lineHeight: '36px',
        },

        '& i': {
          fontSize: '24px',
          lineHeight: '36px',
        },
      },
    },

    body1: {
      margin: 0,
      padding: 0,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18px',
    },

    body2: {
      margin: 0,
      padding: 0,
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
    },

    body3: {
      margin: 0,
      padding: 0,
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '32px',

      '@media (max-width: 899px)': {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
      },
    },

    body4: {
      margin: 0,
      padding: 0,
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '36px',

      '& strong': {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '36px',
      },

      '& i': {
        color: palette.secondary,
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '36px',
      },

      '@media (max-width: 679px)': {
        fontSize: '20px',
        lineHeight: '28px',

        '& strong, & i': {
          fontSize: '20px',
          lineHeight: '28px',
        },
      },
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '& *': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            outline: 'none',
            boxSizing: 'border-box',
          },

          '& a': {
            color: 'inherit',
            textDecoration: 'none',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
      },
    },
  },
});

export default theme;
